import { createReducer, createActions } from 'reduxsauce'


const { Types, Creators } = createActions({
  onLoginSuccess: ['profile', 'accessToken', 'idToken'],
  onLoginFailure: null,
  onLogout:null,
})

export const UserTypes = Types
export default Creators


export const INITIAL_STATE = {
  profile: null,
  error: null,
}


export const onLoginSuccess = (state, action) => {
  return {
    ...state,
    error: null,
    profile: action.profile,
    accessToken: action.accessToken,
    idToken: action.idToken
  }
}

export const onLoginFailure = (state) => {
  return { ...state, error: true, profile: null, accessToken: null, idToken: null }
}

export const onLogout=(state)=>{
  return {...state,error: true, profile: null, accessToken: null, idToken: null }
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ON_LOGIN_SUCCESS]: onLoginSuccess,
  [Types.ON_LOGIN_FAILURE]: onLoginFailure,
  [Types.ON_LOGOUT]:onLogout,
})
