import { createReducer, createActions } from 'reduxsauce';
import DashboardKeys from '../resources/dashboardKeys';

const { Types, Creators } = createActions({
  getProjectSuccess: ['response'],
  getProjectFailure: ['error'],
  getProjectRequest: null,
  setSelectedProject: ['project'],
  updateSelectedProject: ['data'],
  searchProject: ['searchKey'],
  updateProjectRequest: null,
  updateProjectSuccess: ['response'],
  updateProjectFailure: ['error'],
  getMoodboardRequest: ['data'],
  getMoodboardSuccess: ['response'],
  getMoodboardFailure: ['error'],
  setDashboardState: ['key', 'value'],
});

export const DashboardTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  projects: [],
  allProjects: [],
  error: null,
  isLoading: false,
  selectedProject: {},
  moodboard: '',
  isShowPdf: false,
};

export const getProjectSuccess = (state, action) => {
  return {
    ...state,
    error: null,
    projects: action.response.data.projects,
    allProjects: action.response.data.projects,
    isLoading: false,
  };
};

export const setSelectedProject = (state, action) => {
  return {
    ...state,
    error: null,
    selectedProject: action.project,
    isLoading: false,
  };
};

export const updateSelectedProject = (state, action) => {
  const selectedProject = { ...state.selectedProject };
  switch (action.data.key) {
    case DashboardKeys.BUILDING_TYPE:
      return {
        ...state,
        selectedProject: {
          ...selectedProject,
          property: {
            ...selectedProject.property,
            property_type: action.data.value,
          },
        },
      };

    case DashboardKeys.HOUSE_TYPE:
      return {
        ...state,
        selectedProject: {
          ...selectedProject,
          property: {
            ...selectedProject.property,
            flat_type: action.data.value,
          },
        },
      };

    case DashboardKeys.SQFT:
      return {
        ...state,
        selectedProject: {
          ...selectedProject,
          property: { ...selectedProject.property, area: action.data.value },
        },
      };
    
    case DashboardKeys.BUDGET_FROM:
      return {
        ...state,
        selectedProject: {
          ...selectedProject,
          property: {
            ...selectedProject.property,
            budget_from: action.data.value,
          },
        },
      };
    
    case DashboardKeys.BUDGET_TILL:
      return {
        ...state,
        selectedProject: {
          ...selectedProject,
          property: {
            ...selectedProject.property,
            budget_to: action.data.value,
          },
        },
      };

    case DashboardKeys.NOTE:
      return {
        ...state,
        selectedProject: {
          ...selectedProject,
          property: {
            ...selectedProject.property,
            note: action.data.value,
          },
        },
      };
    
      case DashboardKeys.DESIGN_BRIEF:
        return {
          ...state,
          selectedProject: {
            ...selectedProject,
            property: {
              ...selectedProject.property,
              design_brief: action.data.value,
            },
          },
        };
      
      case DashboardKeys.DESIGN_REQUIREMENTS:
        return {
          ...state,
          selectedProject: {
            ...selectedProject,
            property: {
              ...selectedProject.property,
              design_requirements: action.data.value,
            },
          },
        };

      case DashboardKeys.NON_NEGOTIABLES:
        return {
          ...state,
          selectedProject: {
            ...selectedProject,
            property: {
              ...selectedProject.property,
              non_negotiables: action.data.value,
            },
          },
        };
        case DashboardKeys.NAME:
          return {
            ...state,
            selectedProject: {
              ...selectedProject,
              name:action.data.value
            },
          };
          case DashboardKeys.DESIGNER_NAME:
            return {
              ...state,
              selectedProject: {
                ...selectedProject,
                designer_name:action.data.value
              },
            };
            case DashboardKeys.FEES_PAID:
            return {
              ...state,
              selectedProject: {
                ...selectedProject,
                property:{
                  ...selectedProject.property,
                  fees_paid:action.data.value
                },
              },
            };
            case DashboardKeys.SPECIAL_OFFER:
            return {
              ...state,
              selectedProject: {
                ...selectedProject,
                property:{
                  ...selectedProject.property,
                  special_offer:action.data.value
                },
              },
            };
            case DashboardKeys.PROJECT_TYPE:
              return {
                ...state,
                selectedProject: {
                  ...selectedProject,
                  property:{
                    ...selectedProject.property,
                    project_type:action.data.value
                  },
                },
              };
              case DashboardKeys.AGREEMENT_SIGNED:
                return {
                  ...state,
                  selectedProject: {
                    ...selectedProject,
                    property:{
                      ...selectedProject.property,
                      agreement_signed:action.data.value
                    },
                  },
                };
                case DashboardKeys.PAYMENT_METHOD:
                return {
                  ...state,
                  selectedProject: {
                    ...selectedProject,
                    property:{
                      ...selectedProject.property,
                      payment_method:action.data.value
                    },
                  },
                };

                case DashboardKeys.PROPERTY_NAME:
                  return {
                    ...state,
                    selectedProject: {
                      ...selectedProject,
                      property:{
                        ...selectedProject.property,
                        apartment_name:action.data.value
                      },
                    },
                  };

                  case DashboardKeys.POSSESSION_STATUS:
                  return {
                    ...state,
                    selectedProject: {
                      ...selectedProject,
                      property:{
                        ...selectedProject.property,
                        possession_status:action.data.value
                      },
                    },
                  };

                  case DashboardKeys.CUSTOMER_STORY:
                    return {
                      ...state,
                      selectedProject: {
                        ...selectedProject,
                        property:{
                          ...selectedProject.property,
                          customer_story:action.data.value
                        },
                      },
                    };
                    case DashboardKeys.POSSESSION_DATE:
                    return {
                      ...state,
                      selectedProject: {
                        ...selectedProject,
                        property:{
                          ...selectedProject.property,
                          possession_date:action.data.value
                        },
                      },
                    };
                    case DashboardKeys.HANDOVER_DATE:
                      return {
                        ...state,
                        selectedProject: {
                          ...selectedProject,
                          property:{
                            ...selectedProject.property,
                            handover_date:action.data.value
                          },
                        },
                      };
                      case DashboardKeys.DIRECTION_MEETING:
                      return {
                        ...state,
                        selectedProject: {
                          ...selectedProject,
                          property:{
                            ...selectedProject.property,
                            direction_meeting:action.data.value
                          },
                        },
                      };
                      case DashboardKeys.SITE_ADDRESS:
                        return {
                          ...state,
                          selectedProject: {
                            ...selectedProject,
                            property:{
                              ...selectedProject.property,
                              address:action.data.value
                            },
                          },
                        }
                  

    default:
      return { ...state };
  }
};

export const updateProjectRequest = (state, action) => {};

export const getProjectRequest = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getProjectFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
  };
};

export const searchProject = (state, action) => {
  const searchKey = action.searchKey;

  const newProjects = state.allProjects.filter((project) => {
    if (project.name.toLowerCase().indexOf(searchKey.toLowerCase()) > -1) {
      return project;
    } else if ((project.project_no + '').indexOf(searchKey) > -1) {
      return project;
    }
  });

  return {
    ...state,
    projects: newProjects,
  };
};

export const getMoodboardRequest = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getMoodboardSuccess = (state, action) => {
  let moodboard = '';
  let isShowPdf = false;
  if (action.response.data && action.response.data.length) {
    const latestIndex = action.response.data.length - 1;
    moodboard = action.response.data[latestIndex];
    isShowPdf = true;
  }
  return {
    ...state,
    error: null,
    moodboard,
    isShowPdf,
    isLoading: false,
  };
};

export const getMoodboardFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
  };
};

export const setDashboardState = (state, action) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PROJECT_SUCCESS]: getProjectSuccess,
  [Types.GET_PROJECT_FAILURE]: getProjectFailure,
  [Types.GET_PROJECT_REQUEST]: getProjectRequest,
  [Types.SET_SELECTED_PROJECT]: setSelectedProject,
  [Types.UPDATE_SELECTED_PROJECT]: updateSelectedProject,
  [Types.SEARCH_PROJECT]: searchProject,
  [Types.GET_MOODBOARD_REQUEST]: getMoodboardRequest,
  [Types.GET_MOODBOARD_SUCCESS]: getMoodboardSuccess,
  [Types.GET_MOODBOARD_FAILURE]: getMoodboardFailure,
  [Types.SET_DASHBOARD_STATE]: setDashboardState,
});
