import { call, put } from 'redux-saga/effects';
import { path } from 'ramda';
import UserActions from '../redux/UserRedux';
import DashboardActions from '../redux/AllProjectsRedux';

export function* getProjects(api, action) {
  try {
    const response = yield call(api.getProjects);
    yield put(DashboardActions.getProjectSuccess(response.data));
  } catch (error) {
    yield put(DashboardActions.getProjectsFailure(error));
  }
}

export function* getProjectsByDate(api, action) {
  try {
    const response = yield call(api.getProjectsByDate, action.data)
    yield put(DashboardActions.getProjectSuccess(response.data))
  } catch (error) {
    yield put(DashboardActions.getProjectsFailure(error));
  }
}

export function* updateProject(api, action) {
  try {
    const response = yield call(api.getProjects, action.data);
    yield put(DashboardActions.getProjectSuccess(response.data));
  } catch (error) {
    yield put(UserActions.getProjectsFailure(error));
  }
}

export function* getMoodboard(api, action) {
  try {
    const response = yield call(api.getMoodboard, action.data);
    yield put(DashboardActions.getMoodboardSuccess(response.data));
  } catch (error) {
    yield put(DashboardActions.getMoodboardFailure(error));
  }
}
