import { call, put } from 'redux-saga/effects';
import { path } from 'ramda';
import ConfigurationActions from '../redux/ConfigurationRedux';

export function* addMoodBoard(api, action) {
  try {
    const response = yield call(api.addMoodboard, action.data);
    yield put(ConfigurationActions.addMoodboardSuccess(response.data));
  } catch (error) {
    yield put(ConfigurationActions.addMoodboardFailure(error));
  }
}

export function* getFurnitureTemplates(api, action) {
  try {
    const response = yield call(api.getFurnitureTemplates, action.data);
    yield put(ConfigurationActions.getFurnitureTemplatesSuccess(response.data));
  } catch (error) {
    yield put(ConfigurationActions.getFurnitureTemplatesFailure(error))
  }
}