import { call, put } from 'redux-saga/effects';
import { path } from 'ramda';
import DesignStylesActions from '../redux/DesignStyleRedux';

export function* getDesignStyles(api, action) {
  try {
    const response = yield call(api.getDesignStyles);
    yield put(DesignStylesActions.getDesignStylesSuccess(response.data));
  } catch (error) {
    yield put(DesignStylesActions.getDesignStylesFailure(error));
  }
}
