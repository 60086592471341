import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getLibrarySuccess: ['response'],
  getLibraryFailure: ['error'],
  getLibraryRequest: ['data'],
  getCategoryLibrarySuccess: ['data'],
  getCategoryLibraryFailure: ['error'],
  getCategoryLibraryRequest: ['data'],
  set: ['key', 'value'],
});

export const LibraryTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  isCatgory: false,
  libraries: [],
  categories: [],
  error: null,
  isLoading: false,
  categoryLibraries: [],
  selectedId: null,
};

export const getCategoryLibrarySuccess = (state, action) => {
  if (action.data.data.type === 'Category') {
    const newCategories = getNewCategory(
      state.categories,
      action.data.categoryId,
      action.data.data
    );
    return {
      ...state,
      error: null,
      // libraries: action.response.data,
      isLoading: false,
      categories: newCategories,
    };
  } else {
    return {
      ...state,
      error: null,
      libraries: action.data.data.library,
      isLoading: false,
    };
  }
};

const getNewCategory = (categories, categoryId, data) => {
  const newCategory = categories.map((category) => {
    const isCatgory = data.type === 'Category' ? true : false;
    if (category.id === categoryId) {
      return {
        ...category,
        isCatgory,
        categories: data.categories,
      };
    }
    if (category.categories?.length) {
      return getNewCategory(category);
    }
    return category;
  });
  return newCategory;
};

export const getCategoryLibraryRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getCategoryLibraryFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
  };
};
// export const getLibrarySuccess=(state, action) => {
//     return {
//       ...state,
//       error: null,
//       libraries: action.response.data,
//       isLoading:false,
//     }
// }

export const getLibrarySuccess = (state, action) => {
  const isCatgory = action.response.data.type === 'Category' ? true : false;
  return {
    ...state,
    isCatgory,
    error: null,
    categories: action.response.data.categories,
    isLoading: false,
    libraries: action.response.data.library,
  };
};

export const getLibraryRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getLibraryFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
  };
};

export const set = (state, action) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_LIBRARY_SUCCESS]: getLibrarySuccess,
  [Types.GET_LIBRARY_FAILURE]: getLibraryFailure,
  [Types.GET_LIBRARY_REQUEST]: getLibraryRequest,
  [Types.GET_CATEGORY_LIBRARY_SUCCESS]: getCategoryLibrarySuccess,
  [Types.GET_CATEGORY_LIBRARY_FAILURE]: getLibraryFailure,
  [Types.GET_CATEGORY_LIBRARY_REQUEST]: getLibraryRequest,
  [Types.SET]: set,
});
