import { createReducer, createActions } from 'reduxsauce'


const { Types, Creators } = createActions({
    getDesignStylesSuccess: ['response'],
    getDesignStylesFailure: ['error'],
    getDesignStylesRequest:null,
  })


export const DesignStyleTypes = Types
export default Creators


export const INITIAL_STATE = {
  designStyles: [],
  error: null,
  isLoading:false,
}



export const getDesignStylesSuccess=(state, action) => {
    return {
      ...state,
      error: null,
      designStyles: action.response.data.styles,
      isLoading:false,
    }
  }


  export const getDesignStylesRequest=(state)=>{
      return{
          ...state,
          isLoading:true
      }
  }


  export const getDesignStylesFailure=(state,action)=>{
      return{
          ...state,
          isLoading:false,
          error:action.error
      }
  }


  export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_DESIGN_STYLES_SUCCESS]: getDesignStylesSuccess,
    [Types.GET_DESIGN_STYLES_FAILURE]: getDesignStylesFailure,
    [Types.GET_DESIGN_STYLES_REQUEST]: getDesignStylesRequest
  })
  