import apisauce from 'apisauce';

const create = (baseURL = "https://app.bonito.in/api/moodboard/") => {
  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("BONITO_TOKEN")}`
    },
    timeout: 10000,
  });

  const getProjects = () => api.get('/projects');

  const getProjectsByDate = (data) => api.get(`/projects/?start_date=${data.startData}&end_date=${data.endDate}`)

  const getUser = () => api.get('users');

  const getDesignStyles = () => api.get('/library/design_styles');

  const getAreas = () => api.get('/areas');

  const updateProject = (data) => api.put(`/projects/${data.id}`, data.project);

  const getLibraries = (data) =>
    api.get(`/library?area=${data.areaId}&category=${data.categoryId}`);

  const getBriefnote = (id) => api.get(`/projects/${id}/briefnote`);

  const addMoodboard = (data) => {
    const form = new FormData();
    const newHeaders = {
      'Content-Type': 'multipart/form-data',
      'Cache-Control': 'no-cache',
      'Authorization': `Bearer ${localStorage.getItem("BONITO_TOKEN")}`
    };
    form.append('name', 'test2.pdf');
    form.append('file', data.file);

    return api.post(`/projects/${data.id}/moodboard`, form, {
      headers: newHeaders,
    });
  };

  const getMoodboard = (data) => api.get(`/projects/${data.id}/moodboard`);

  const getFurnitureTemplates = () => api.get('/library/furniture_templates')

  return {
    getUser,
    getProjects,
    getDesignStyles,
    updateProject,
    getAreas,
    getLibraries,
    getBriefnote,
    addMoodboard,
    getMoodboard,
    getFurnitureTemplates
  };
};

export default {
  create,
};
