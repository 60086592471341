const DashboardKeys={
BUILDING_TYPE:'BULDING_TYPE',
HOUSE_TYPE:'HOUSE_TYPE',
SQFT:'SQFT',
BUDGET_FROM:'BUDGET_FROM',
BUDGET_TILL:'BUDGET_TILL',
NOTE:'NOTE',
DESIGN_BRIEF:'DESIGN_BRIEF',
DESIGN_REQUIREMENTS:'DESIGN_REQUIREMENTS',
NON_NEGOTIABLES:'NON_NEGOTIABLES',
NAME:'name',
DESIGNER_NAME:'DESIGNER_NAME',
FEES_PAID:'FEES_PAID',
SPECIAL_OFFER:'SPECIAL_OFFER',
PROJECT_TYPE:'PROJECT_TYPE',
AGREEMENT_SIGNED:'AGREEMENT_SIGNED',
PAYMENT_METHOD:'PAYMENT_METHOD',
PROPERTY_NAME:'PROPERTY_NAME',
POSSESSION_STATUS:'POSSESSION_STATUS',
CUSTOMER_STORY:'CUSTOMER_STORY',
SITE_ADDRESS:'SITE_ADDRESS',
POSSESSION_DATE:'POSSESSION_DATE',
HANDOVER_DATE:'HANDOVER_DATE',
DIRECTION_MEETING:'DIRECTION_MEETING'
}

export default DashboardKeys