import { call, put } from 'redux-saga/effects';

import AreasAction from '../redux/ConfigurationRedux';

export function* getAreas(api, action) {
  try {
    const response = yield call(api.getAreas);
    yield put(AreasAction.getAreasSuccess(response.data));
  } catch (error) {
    yield put(AreasAction.getAreasFailure(error));
  }
}

export function* getNotes(api, action) {
  try {
    const response = yield call(api.getBriefnote, action.projectId);
    const note = response.data?.data;
    yield put(AreasAction.getNoteSuccess(note));
  } catch (error) {
    yield put(AreasAction.getNoteFailure(error));
  }
}
