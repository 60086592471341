import { takeLatest, all, takeEvery } from 'redux-saga/effects';
import API from '../services/Api';

import { UserTypes } from '../redux/UserRedux';
import { getUser } from './UserSagas';
import { getDesignStyles } from './DesignStylesSagas';
import { getAreas, getNotes } from './AreasSagas';
import { DesignStyleTypes } from '../redux/DesignStyleRedux';
import { getMoodboard, getProjects } from './AllProjectsSagas';
import { DashboardTypes } from '../redux/AllProjectsRedux';
import { LibraryTypes } from '../redux/LibraryRedux';
import { addMoodboardRequest, AreaTypes } from '../redux/ConfigurationRedux';
import { getLibraries, getCategoryLibraries } from './LibrarySagas';
import { addMoodBoard, getFurnitureTemplates } from './ConfigurationSaga';
// import ConfigurationActions from '../redux/ConfigurationRedux';

const api = API.create();

export default function* root() {
  // yield all([

  //   takeLatest(UserTypes.USER_REQUEST, getUser, api),
  // ]);
  yield takeEvery(DashboardTypes.GET_PROJECT_REQUEST, getProjects, api);

  yield takeEvery(
    DesignStyleTypes.GET_DESIGN_STYLES_REQUEST,
    getDesignStyles,
    api
  );

  yield takeEvery(AreaTypes.GET_AREAS_REQUEST, getAreas, api);

  yield takeEvery(LibraryTypes.GET_LIBRARY_REQUEST, getLibraries, api);

  yield takeEvery(
    LibraryTypes.GET_CATEGORY_LIBRARY_REQUEST,
    getCategoryLibraries,
    api
  );

  yield takeEvery(AreaTypes.GET_NOTE_REQUEST, getNotes, api);

  yield takeEvery(AreaTypes.ADD_MOODBOARD_REQUEST, addMoodBoard, api);

  yield takeEvery(DashboardTypes.GET_MOODBOARD_REQUEST, getMoodboard, api);

  yield takeEvery(AreaTypes.GET_FURNITURE_TEMPLATES_REQUEST, getFurnitureTemplates, api)
}
