import { combineReducers } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import configureStore from './CreateStore'
import rootSaga from '../sagas'

export const reducers = combineReducers({
  user: require('./UserRedux').reducer,

  designStyles: require('./DesignStyleRedux').reducer,
  dashboard:require('./AllProjectsRedux').reducer,
  configuration: require('./ConfigurationRedux').reducer,
  library: require('./LibraryRedux').reducer
})

export default () => {
  let finalReducers = persistReducer({
    key: 'root',
    storage,
  }, reducers)

  let { store, sagasManager, sagaMiddleware } = configureStore(finalReducers, rootSaga)

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('./').reducers
      store.replaceReducer(nextRootReducer)

      const newYieldedSagas = require('../sagas').default
      sagasManager.cancel()
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware(newYieldedSagas)
      })
    })
  }

  let persistor = persistStore(store)
  return { store, persistor }
}
