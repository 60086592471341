import React from 'react'
import './loader.css'
import ImageAssests from '../../../assets/index';

const SuspenseLoader = ({ isTransparent }) => {
    const backgroundColor = isTransparent ? "#00000000" : "#fff"
    return (
        <div className={'loader-container'} style={{ backgroundColor }}>
            <img src={ImageAssests.ic_sus_loading} />
        </div>
    )
}

export default SuspenseLoader