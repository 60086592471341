import React, { lazy } from 'react';
import { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SuspenseLoader from '../components/ui/loader/SuspenseLoader';
// import Login from './welcome/Login';
// import Dashboard from './dashboard/Dashboard';
// import DesignStyleEducation from './designStyleEducation/DesignStyleEducation';
// import Configuration from './configuration/Configuration';

const Login = lazy(() => import('./welcome/Login'))
const Dashboard = lazy(() => import('./dashboard/Dashboard'))
const DesignStyleEducation = lazy(() => import('./designStyleEducation/DesignStyleEducation'))
const Configuration = lazy(() => import('./configuration/Configuration'))

export const RouteNames = {
  LOGIN: "/",
  DASHBOARD: "/dashboard",
  DESIGNSTYLEEDUCATION: "/design_style_education",
  CONFIGURATION: "/configuration"
}


export default function Routes() {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Switch>
        <Route path={RouteNames.LOGIN} exact component={Login} />
        <Route path={RouteNames.DASHBOARD} component={Dashboard} isPrivate />
        <Route path={RouteNames.CONFIGURATION} component={Configuration} isPrivate />
        <Route component={Login} />
      </Switch>
    </Suspense>
  );
}