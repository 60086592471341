import { call, put } from 'redux-saga/effects';
import { path } from 'ramda';
import LibraryActions from '../redux/LibraryRedux';

export function* getLibraries(api, action) {
  try {
    const response = yield call(api.getLibraries, action.data);
    yield put(LibraryActions.getLibrarySuccess(response.data));
  } catch (error) {
    yield put(LibraryActions.getLibraryFailure(error));
  }
}

export function* getCategoryLibraries(api, action) {
  try {
    const response = yield call(api.getLibraries, action.data);
    const data = { ...response.data, categoryId: action.data.categoryId };
    yield put(LibraryActions.getCategoryLibrarySuccess(data));
  } catch (error) {
    yield put(LibraryActions.getCategoryLibraryFailure(error));
  }
}
