import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getAreasSuccess: ['response'],
  getAreasFailure: ['error'],
  getAreasRequest: null,
  set: ['key', 'value'],
  setConfigurationState: ['key', 'value'],
  resetState: null,
  getNoteRequest: ['projectId'],
  getNoteFailure: ['error'],
  getNoteSuccess: ['response'],
  addMoodboardRequest: ['data'],
  addMoodboardFailure: ['error'],
  addMoodboardSuccess: ['response'],
  getFurnitureTemplatesSuccess: ['response'],
  getFurnitureTemplatesRequest: null,
  getFurnitureTemplatesFailure: ['error']

});

export const AreaTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  areas: [],
  selectedAreas: [],
  selectedArea: {},
  error: null,
  isLoading: false,
  selectedAreaIndex: null,
  selectedFloor: {},
  selectedFloorIndex: null,
  selectedFloors: [],
  furnitureLibrary: [],
  isActionLoading:false
};

export const getAreasSuccess = (state, action) => {
  const selectedAreas = action.response.data.areas.filter(
    (area) => area.default
  );

  const newSelectedArea = selectedAreas.map(area=>({...area,areaId:area.id})) 
  const newAreas= action.response.data.areas.map(area=>({...area,areaId:area.id}))
  return {
    ...state,
    error: null,
    areas: newAreas,
    selectedAreas:newSelectedArea,
    isLoading: false,
  };
};

export const set = (state, action) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const setConfigurationState = (state, action) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const getNoteRequest = (state, action) => {
  return {
    ...state,
  };
};

export const getNoteSuccess = (state, action) => {
  return {
    ...state,
    briefnote: action.response.note,
  };
};

export const getNoteFailure = (state, action) => {
  return {
    ...state,
    error: action.error,
  };
};

export const getAreasRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const resetState = (state) => {
  return INITIAL_STATE;
};

export const getAreasFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
  };
};

export const addMoodboardRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const addMoodboardFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const addMoodboardSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const getFurnitureTemplatesRequest = (state, action) => {
  return {
    ...state,
    isLoading: true
  }
}

export const getFurnitureTemplatesSuccess = (state, { response }) => {
  return {
    ...state,
    isLoading: false,
    furnitureLibrary: response?.data?.furniture_templates
  }
}

export const getFurnitureTemplatesFailure = (state, action) => {
  return {
    ...state,
    isLoading: false
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_AREAS_SUCCESS]: getAreasSuccess,
  [Types.GET_AREAS_FAILURE]: getAreasFailure,
  [Types.GET_AREAS_REQUEST]: getAreasRequest,
  [Types.SET]: set,
  [Types.RESET_STATE]: resetState,
  [Types.GET_NOTE_REQUEST]: getNoteRequest,
  [Types.GET_NOTE_SUCCESS]: getNoteSuccess,
  [Types.GET_NOTE_FAILURE]: getNoteFailure,
  [Types.SET_CONFIGURATION_STATE]: setConfigurationState,
  [Types.ADD_MOODBOARD_REQUEST]: addMoodboardRequest,
  [Types.ADD_MOODBOARD_FAILURE]: addMoodboardFailure,
  [Types.ADD_MOODBOARD_SUCCESS]: addMoodboardSuccess,
  [Types.GET_FURNITURE_TEMPLATES_REQUEST]: getFurnitureTemplatesRequest,
  [Types.GET_FURNITURE_TEMPLATES_SUCCESS]: getFurnitureTemplatesSuccess,
  [Types.GET_FURNITURE_TEMPLATES_FAILURE]: getFurnitureTemplatesFailure,

});
