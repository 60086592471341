import ic_bonitoLogo from "./icons/bonito_logo.png"
import ic_status_online from "./icons/status_online.svg"
import img_create_moodbord_bg from "./images/create_moodbord_bg.png"
import ic_create_project from "./icons/create_project.svg"
import ic_arrow from "./icons/arrow.svg"
import ic_arrow_active from "./icons/arrow_active.svg"
import ic_design from "./icons/design.svg"
import img_moodboard_logo from "./images/moodboard_logo.png"
import ic_radio_un_selected from './icons/radio_unselected.svg'
import ic_dropdown from './icons/dropdown.svg'
import ic_my_projects from './icons/my_projects.svg'
import ic_design_style_education from './icons/design_style_education.svg'
import ic_1_rupee_symbol from './icons/1_rupee.svg'
import ic_2_rupee_symbol from './icons/2_rupee.svg'
import ic_3_rupee_symbol from './icons/3_rupee.svg'
import ic_4_rupee_symbol from './icons/4_rupee.svg'
import ic_radio_inner_selected from './icons/radio_selected.svg'
import ic_add from './icons/add.svg'
import ic_delete from './icons/delete.svg'
import img_layout_1 from './images/layout_1.svg'
import img_layout_2 from './images/layout_2.svg'
import img_layout_3 from './images/layout_3.svg'
import img_layout_4 from './images/layout_4.svg'
import img_layout_5 from './images/layout_5.svg'
import img_layout_6 from './images/layout_6.png'
import img_refLayout_1 from './images/refLayout_1.png'
import img_refLayout_2 from './images/refLayout_2.png'
import img_refLayout_3 from './images/refLayout_3.png'
import img_refLayout_4 from './images/refLayout_4.png'
import img_refLayout_5 from './images/refLayout_5.png'
import img_refLayout_6 from './images/refLayout_6.png'
import ic_rotate_left from './icons/ic_rotate_left.svg'
import ic_rotate_right from './icons/ic_rotate_right.svg'
import img_pdf_background from './images/pdf_background.png'
import img_male from './images/img_male.png'
import img_female from './images/img_female.png'
import img_thank_you from './images/img_thank_you.png'
import ic_edit from './icons/edit.svg'
import ic_upload from './icons/ic_upload.svg'
import ic_close from './icons/ic_x.svg'
import ic_draw from './icons/ic_draw.svg'
import ic_erase from './icons/ic_erase.svg'
import ic_add_text from './icons/ic_add_text.svg'
import img_layout_bonito_logo from './images/img_layout_bonito_logo.jpg'
import ic_zoom_in from './icons/ic_zoom_in.svg'
import ic_zoom_out from './icons/ic_zoom_out.svg'
import ic_search from './icons/ic_search.svg'
import ic_arrow_doted from './icons/ic_arrow_doted.svg'
import ic_delete_red from './icons/ic_delete_red.svg'
import ic_edit_trans from './icons/ic_edit_trans.svg'
import img_layout_1_trans from './images/img_layout_1_trans.svg'
import ic_upload_blue from './icons/ic_upload_blue.svg'
import ic_delete_black from './icons/delete_black.svg'
import ic_logout from './icons/power.svg'
import ic_pencil from './icons/ic_pencil.png'
import ic_eraser from './icons/ic_eraser.png'
import ic_sus_loading from './icons/sus_loading.gif'
import img_agenda from './images/img_agenda.png'
import img_jorney from './images/img_jorney.png'
import ic_clear_furniture from './icons/clear_furniture.svg'
import ic_discard_and_close from './icons/discard_and_close.svg'
import ic_replace_image from './icons/replace_image.svg'
import ic_save_and_close from './icons/save_and_close.svg'
import ic_unlock from './icons/ic_unlock.png'
import ic_lock from './icons/ic_lock.png'
import img_reference_bg from './images/img_reference_bg.png'
import ic_header_logo from './icons/ic_header_logo.png'

const ImageAssests = {
    ic_bonitoLogo,
    ic_status_online,
    img_create_moodbord_bg,
    ic_create_project,
    ic_arrow,
    ic_design,
    img_moodboard_logo,
    ic_radio_un_selected,
    ic_dropdown,
    ic_my_projects,
    ic_design_style_education,
    ic_1_rupee_symbol,
    ic_2_rupee_symbol,
    ic_3_rupee_symbol,
    ic_4_rupee_symbol,
    ic_arrow_active,
    ic_radio_inner_selected,
    ic_add,
    ic_delete,
    img_layout_1,
    img_layout_2,
    img_layout_3,
    img_layout_4,
    img_layout_5,
    img_layout_6,
    ic_rotate_right,
    ic_rotate_left,
    img_pdf_background,
    img_male,
    img_female,
    img_thank_you,
    ic_edit,
    ic_upload,
    ic_close,
    ic_draw,
    ic_erase,
    ic_add_text,
    img_layout_bonito_logo,
    ic_zoom_in,
    ic_zoom_out,
    ic_search,
    ic_arrow_doted,
    ic_delete_red,
    ic_edit_trans,
    img_layout_1_trans,
    ic_upload_blue,
    ic_delete_black,
    ic_logout,
    ic_header_logo,

    img_refLayout_1,
    img_refLayout_2,
    img_refLayout_3,
    img_refLayout_4,
    img_refLayout_5,
    img_refLayout_6,
    ic_sus_loading,
    img_agenda,
    img_jorney,


    // Floor plan editor icons
    ic_clear_furniture,
    ic_discard_and_close,
    ic_replace_image,
    ic_save_and_close,
    ic_pencil,
    ic_eraser,


    ic_unlock,
    ic_lock,

    img_reference_bg
}

export default ImageAssests